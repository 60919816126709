/*------------------------------------*\
  Webform
\*------------------------------------*/
form.webform-client-form{

    > div{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .form-item{
        margin-bottom: 1rem; 
        width: 100%;
    }

    @include larger-than($screen-md){
        .form-item{
            &.col-2{
                width: 50%;
            }

            &.col-left{
                padding-right: 1rem;
            }

            &.col-right{
                padding-left: 1rem;
            }

        }
    }


    input,
    textarea {
      background: #ffffff;
      border: 1px solid #cccccc;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      padding: 1rem;
      color: #333;
      width: 100%;

      &::placeholder {
        color: #898581;
      }
    }

    .form-actions{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        button.webform-submit{
          height: 48px;
          min-width: 133px;
          @extend .button;
          @extend .button-primary;
          border-radius: 3px;
        }
      }

}