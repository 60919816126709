/*------------------------------------*\
  Product Content type
\*------------------------------------*/
.page-node.node-type-product {
  .page_header {
    display: none;
  }

  @include less-than($screen-lg) {
    .node.node-product {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .group-content-section {
    display: flex;
    margin-bottom: 4rem;
    @include less-than($screen-lg) {
      flex-direction: column;
    }

    .product-col {
      width: 100%;

      @include larger-than($screen-lg) {
        &.product-left {
          width: 60%;
        }
        &.product-right {
          width: 40%;
        }
      }
    }

    .product-left {
      padding: 2rem 0;
      position: relative;

      @include larger-than($screen-lg) {
        padding: 2rem 4rem 2rem 2rem;
      }

      .slick {
        .media--image {
          img {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      .slick-wrapper {
        display: flex;

        .slick {
          &--main,
          &--optionset--slick-products {
            width: 100%;
            order: 1;
            img {
              width: 100%;
              margin: 0 auto;
              object-fit: contain;
              cursor: url('../../images/icon--magnifier.svg'), auto;
            }

            .slick__arrow {
              @apply tw-absolute
              tw-w-full
              tw-h-full
              tw-top-0
              tw-left-0
              tw-flex
              tw-justify-between;
              .slick-arrow {
                font-size: 0;
                z-index: 50;
                padding: 0.5rem;
                outline: none;
                &::before {
                  font-size: 4rem;
                  color: $color-primary;
                }
              }
            }
          }

          &--thumbnail {
            position: relative;
            width: auto;
            order: 0;
            display: none;

            .slick-slide {
              display: inline-block;
              vertical-align: middle;
              float: none;
              .slide__thumbnail {
                padding: 1rem 0;
              }
            }

            .slide__thumbnail {
              @include imgZoomHover;
              img {
                width: auto;
                height: 65px;
                object-fit: cover;
                margin: 0 auto;
                cursor: pointer;
              }
            }

            .slick__arrow {
              position: absolute;
              top: -5%;
              left: 0;
              z-index: 5;
              height: 105%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .slick-arrow {
                outline: none;
                padding: 0.5rem 1.5rem;
                margin: 0;
                &::before {
                  font-family: $font-awesome;
                  color: #000;
                  font-size: 2.5rem;
                }
                &.slick-prev {
                  order: 1;
                  &::before {
                    content: '\f107';
                  }
                }
                &.slick-next {
                  order: 0;
                  &::before {
                    content: '\f106';
                  }
                }
              }
            }
          }

          @include larger-than(720px) {
            &--main {
              width: 80%;
              img {
                object-fit: contain;
                height: 500px;
              }
            }
            &--thumbnail {
              width: 20%;
              display: block;
            }
          }

          &__slider {
            z-index: 10;
          }
        }
      }

      .product-image {
        display: flex;

        .more-product-images {
          order: -1;
          width: 150px;
          img {
            width: 100px;
            height: 100px;
            margin: 0 auto;
          }
        }
      }
    }

    .product-right {
      color: #3f3f3f;

      .field-name-title {
        h1,
        h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 118.69%;
          letter-spacing: 0.02em;
          color: #3f3f3f;
        }
      }

      .group-price-info-wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e1e1e1;

        .field-name-field-shipping-class {
          display: flex;
          margin-bottom: 2rem;
          img {
            width: 69px;
            height: 69px;
            object-fit: contain;
          }
        }

        .model {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 149.69%;
          text-transform: uppercase;
        }

        .display-price-range,
        .display-price {
          text-align: left;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 149.69%;
          color: $color-primary;
          margin: 1rem 0 2rem;
          .uc-price {
            &::after {
              content: ' + gst';
            }
          }

          .uc-promo-price {
            font-weight: 600;
            font-size: 18px;
            line-height: 1.5;
          }
        }

        @include larger-than($screen-sm) {
          padding-right: 8rem;

          .field-name-field-shipping-class {
            position: absolute;
            right: 0;
            margin: 0;
          }

          .display-price-range,
          .display-price {
            font-size: 30px;
          }
        }
      }

      .group-units-row {
        padding-top: 1rem;
      }

      /*------------------------------------*\
        Cart
      \*------------------------------------*/
      .add-to-cart {
        margin: 3rem 0 1rem 0;
        > form {
          > div {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            > label {
              margin-bottom: 1rem;
              font-weight: bold;
            }

            > .view-related-products {
              position: relative;
              width: 100%;
              margin-bottom: 3rem;
              padding-bottom: 1rem;
              border-bottom: 1px solid #e2e8f0;

              .views-row {
                > .node-product {
                  display: flex;
                  > .display-price-range {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }
        /** Related Products View **/
        .addons {
          width: 100%;
        }
        .addon {
          width: 100%;
          padding-top: 2rem;
          padding-bottom: 2rem;

          .form-item {
            font-size: 1.4rem;
            label {
              display: flex;
              align-items: center;
              margin-bottom: 0.5rem;
              input {
                margin: 0 1rem 0 0;
              }
            }
          }
        }
        .attributes {
          width: 100%;
          padding-bottom: 3rem;
          margin-bottom: 3rem;
          border-bottom: 1px solid #e1e1e1;
        }
        .attribute {
          margin-bottom: 1rem;
          > .form-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          label {
            font-weight: normal;
            font-size: 14px;
            line-height: 149.69%;
            width: 100px;
            display: inline-block;
          }
          input[type='text'],
          select {
            width: 270px;
            background: #ffffff;
            border: 1px solid #adadad;
            box-sizing: border-box;
            padding: 4px 5px;
            font-size: 14px;
            font-weight: normal;
          }
        }
        .form-item-qty {
          margin: 0;
          display: flex;
          align-items: center;
          label {
            font-weight: 500;
            font-size: 20.703px;
            line-height: 149.69%;
            letter-spacing: 0.02em;
            color: #262626;
            margin-right: 1rem;
            font-size: 0;

            .form-required {
              display: none;
            }

            &::before {
              content: 'Qty:';
              font-size: 2rem;
            }
          }
          input.form-text {
            width: 52px;
            height: 5.8rem;
            text-align: center;
            display: inline-block;
            font-weight: 500;
            font-size: 20.703px;
            line-height: 149.69%;
            letter-spacing: 0.02em;
            color: #0b0b0b;
            border: 2px solid rgba(0, 0, 0, 0.3);
          }

          .form-qty {
            display: flex;
            justify-content: center;
            align-items: stretch;
            border: 2px solid rgba(0, 0, 0, 0.3);

            input.form-text {
              border: none;
            }

            .increment {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              font-weight: bold;
              font-size: 2.5rem;
              color: rgba(0, 0, 0, 0.3);
              cursor: pointer;
              &:hover {
                color: $color-primary;
              }
              &.minus {
                border-right: 2px solid rgba(0, 0, 0, 0.3);
              }
              &.plus {
                border-left: 2px solid rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
        .form-actions {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding-left: 1rem;

          > .btn {
            order: 0;
            width: 100%;
            height: 61px;
            font-size: 2.5rem;
          }

          .uc-ajax-cart-alt-status-messages {
            position: relative;
            order: 1;
            .alert {
              position: absolute;
              left: 0;
              animation: 1s fadeOut;
              animation-delay: 1s;
              animation-fill-mode: forwards;
              display: inline;
              font-size: 14px;
              padding: 1rem 3rem 1rem 1rem;
            }
          }
        }
        .node-add-to-cart {
          @extend .button;
          @extend .button-primary;
          width: 475px;
          max-width: 100%;
          border-radius: 3.62px;
        }

        form {
          > div {
            @include less-than(480px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .form-actions {
                padding-left: 0;
              }
              .form-item-qty {
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
      /** Stock **/

      // Display Suite Code Field
      .product-label {
        display: inline-flex;
        padding: 0.6rem 1.2rem;
        font-weight: 600;
        border-radius: 15px;
        margin-top: 2rem;
        align-items: center;
        line-height: 1;

        &::before {
          font-family: $font-awesome;
          font-weight: 600;
          margin-right: 0.5rem;
        }

        &--out_of_stock {
          border: 1px solid #d30000;
          background-color: #d30000;
          color: #fff;
          &::before {
            content: '\f057';
          }
        }

        &--in_stock {
          border: 1px solid #8db735;
          background-color: #8db735;
          color: #fff;
          &::before {
            content: '\f058';
          }
        }
      }

      .field-name-field-stock {
        .field-item {
          padding: 1rem 0;
          color: #779d0a;
          &::before {
            content: '\f058';
            font-family: $font-awesome;
            font-weight: 600;
            margin-right: 0.5rem;
          }
        }
      }

      .field-name-field-certificate-icon {
        margin-top: 1.5rem;
        .field-items {
          position: relative;
          width: 100%;
          display: flex;
          gap: 1rem;
        }
      }

      .field-name-product-enquiry-link,
      .group-description-section {
        display: none;
      }
    }
  }

  /*------------------------------------*\
    Tabs
  \*------------------------------------*/
  .group-prodcut-tabs {
    .horizontal-tabs {
      border: none;
    }
    fieldset.horizontal-tabs-pane {
      padding-left: 0;
      padding-right: 0;
    }
    .horizontal-tabs-list {
      background: none;
      border: none;
      margin-bottom: 2rem;

      li {
        position: relative;
        padding: 0 0 1rem 0;
        border: 0;
        background: unset;
        &::before {
          @include animated;
          content: '';
          position: absolute;
          width: 0;
          height: 3px;
          bottom: 0;
          left: 0;
          opacity: 0;
          z-index: 2;
          background-color: $color-primary;
        }

        &:not(.selected) {
          &:hover {
            &::before {
              width: 100%;
              opacity: 1;
              background-color: lighten($color-primary, 40%);
            }
          }
        }

        &.selected {
          //border-color: $color-primary;
          background-color: #fff;
          &::before {
            width: 100%;
            opacity: 1;
          }
        }
        a {
          &:hover {
            background-color: #fff;
          }
        }
      }

      @include less-than($screen-md) {
        @apply tw-flex tw-flex-col tw-text-center;
      }
    }

    .group-description {
      padding: 0;
    }
  }

  /*------------------------------------*\
    Product Enquiry
  \*------------------------------------*/
  .field-name-product-enquiry-form {
    form.webform-client-form {
      .form-item,
      .form-textarea-wrapper {
        @apply tw-flex tw-mb-4 tw-w-full;
        .form-control {
          flex: 1;
          background-color: #f0f0f0;
          border: 1px solid darken(#f0f0f0, 2%);
          @apply tw-w-full tw-p-4;
        }
        .grippie {
          @apply tw-hidden;
        }
      }

      .form-actions {
        .form-submit {
          @include btn($color-primary);
        }
      }
    }
    @include larger-than($screen-md) {
      form.webform-client-form {
        width: 50%;
      }
    }
  }

  /*------------------------------------*\
    Accessories, Alternative Products
  \*------------------------------------*/
  .field-name-field-addon-products,
  .field-name-field-alternative-products {
    > .field-items {
      @include grid-layout(5, 5rem);
      @include less-than($screen-xl) {
        @include grid-layout(4, 2rem);
      }
      @include less-than($screen-lg) {
        @include grid-layout(2, 2rem);
      }
      @include less-than($screen-md) {
        @include grid-layout(1, 0rem);
        .field-items {
          margin-bottom: 2rem;
        }
      }
    }
  }

  /*------------------------------------*\
    Product
  \*------------------------------------*/
  .product {
    /** Related **/
    &__related {
      margin-top: 4rem;
      .block-title {
        font-weight: 600;
        font-size: 18px;
        color: #3f3f3f;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }

  /*------------------------------------*\
    Content - file
  \*------------------------------------*/
  .content {
    .file {
      display: flex;
      img {
        height: 18px;
        margin-top: 4px;
        margin-right: 4px;
      }
    }
  }
}

/*------------------------------------*\
  Node Product - Teaser 
\*------------------------------------*/
.node-product.node-teaser {
  @include product-card;
}

/*------------------------------------*\
  Product Title Price
\*------------------------------------*/
.node-product.view-mode-product_title_price {
  display: flex;
  margin-bottom: 1rem;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .field-item {
    font-size: 1.4rem;
    line-height: 1.5;
    padding-right: 1rem;
    a {
      color: $color-primary;
    }
  }

  .uc-price {
    font-size: 1.4rem;
    color: #3f3f3f;
    padding: 0 1rem;
    &::before {
      content: '(+';
    }
    &::after {
      content: ')';
    }
  }
}
