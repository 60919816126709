/*------------------------------------*\
    Component: Breadcrumb
\*------------------------------------*/
.region-breadcrumbs {
  margin: 1rem 0;
  font-size: 1.4rem;
  font-weight: 300;
  display: flex;
  justify-content: space-between;

  .easy-breadcrumb_segment-separator {
    color: #999;
  }
  .easy-breadcrumb_segment-title{
    color: #999;
  }
  a.easy-breadcrumb_segment {
    @include link;
  }

  @include less-than($screen-md){
    flex-direction: column;
    section{
      margin-bottom: 2rem;
    }
  }

}
