/*------------------------------------*\
  Modal
\*------------------------------------*/

body {
  &.cart,
  &.checkout {
    #cboxWrapper,
    #cboxContent,
    #cboxLoadedContent {
      @extend .animate__animated;
      @extend .animate__fadeIn;
      background-color: $color-primary;
      color: #fff;
    }
    #cboxClose {
      outline: none;
      background: none;
      &::before {
        outline: none;
        display: flex;
        content: '\f00d';
        font-family: $font-awesome;
        height: auto;
        width: auto;
        color: #fff;
        text-indent: 0;
      }
    }
  }
}

#koda_checkout_modal {
  background-color: $color-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  overflow: hidden;

  .koda_addon_modal_title {
    font-size: 4.5rem;
    line-height: 1;
    margin-bottom: 2rem;
    @extend .animate__animated;
    @extend .animate__bounceIn;
  }
}
