.block-uc-ajax-cart-alt {
  opacity: 0;
  width: 53px;
  height: 50px;

  // .cart-block-summary .cart-block-summary-links
  .cart-block-items {
    display: none !important;
  }

  &.block-uc-cart {
    position: relative;
    display: flex;
    width: 100%;
    opacity: 1;
    flex-direction: column;
    justify-content: flex-start;
    color: #000 !important;

    &:hover {
      color: $color-primary;
      &:before,
      &::after {
        color: $color-primary !important;
      }
    }

    &:before {
      content: '\f07a';
      font-family: $font-awesome;
      font-size: 27px;
      font-weight: 200;
      line-height: 1.2;
      margin-top: 0;
      height: auto;
      @include less-than($screen-lg) {
        font-size: 22px;
      }
    }

    &:after {
      content: 'My Cart';
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      margin-top: 0;
      visibility: visible !important;
      @include less-than($screen-md) {
        font-size: 10px;
      }
    }

    .cart-block-view-cart {
      > a {
        &:hover {
          color: $color-primary;
        }
      }
    }

    // h2.block-title {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   height: 100%;
    //   margin: 0;
    //   font-size: 0;

    //   > span {
    //     display: none;
    //   }

    //   &:before {
    //     content: '\f07a';
    //     font-family: $font-awesome;
    //     font-size: 27px;
    //     font-weight: 200;
    //     margin-top: 0;
    //     line-height: 1;
    //     @include less-than($screen-lg) {
    //       font-size: 24px;
    //     }
    //   }

    //   &:after {
    //     content: 'My Cart';
    //     font-size: 14px;
    //     font-weight: 600;
    //     line-height: 1;
    //     margin-top: auto;
    //     @include less-than($screen-md) {
    //       font-size: 10px;
    //     }
    //   }
    // }

    table {
      display: block;
      height: 0;
      padding: 0;

      .cart-block-summary-items {
        color: transparent;
        display: block;
        height: 0;
      }

      .cart-block-summary-links {
        .links {
          padding: 0;
          margin: 0;
        }
        .cart-block-view-cart {
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 53px;
            height: 53px;
            font-size: 0;
            color: transparent;
          }
        }
        .cart-block-checkout {
          display: none !important;
        }
      }

      .cart-block-summary-total {
        display: none;
      }

      .num-items {
        position: absolute;
        right: -5px;
        top: 0px;
        color: #333;
        width: 21px;
        height: 21px;
        background-color: #e7760e;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: bold;

        @include less-than($screen-lg) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
