/*------------------------------------*\
    Global Page Styles
\*------------------------------------*/

/*
======================================================
: Global hidden elements
======================================================
*/
#sidebar-trigger {
  display: none;
}

/*
======================================================
: Inifinite scroll loader
======================================================
*/
.views_infinite_scroll-ajax-loader {
  margin: 4rem 0;
  display: flex;
  justify-content: center;
}

/*
======================================================
 : Table Responsive
======================================================
*/
.table-responsive {
  
  @include less-than($screen-md){
    min-height: 0.01%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table {
      margin-bottom: 0;
      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }
  }

}
