/*------------------------------------*\
   Header
\*------------------------------------*/

.header {
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

  .region {
    @apply tw-flex tw-justify-center tw-items-center;
  }
  .region-header-menu {
    @apply tw-flex tw-items-center;
    justify-content: normal;
  }

  .tw-container {
    padding: 1rem 2rem 1.5rem 2rem;
  }

  @include larger-than($screen-md) {
    .region-header {
      min-height: 105px;
    }
  }

  /** logo **/
  .block-header-logo {
    flex: 0 0 auto;
    margin-right: 5rem;
    img {
      width: 100px;
      max-width: auto;
      object-fit: contain;
    }
    @include larger-than($screen-lg) {
      img {
        --logoWidth: 120px;
        min-width: var(--logoWidth);
      }
    }
    @include larger-than($screen-xxl) {
      img {
        --logoWidth: 180px;
      }
    }
    @include less-than($screen-sm) {
      margin-right: auto;
    }
  }

  .block-koda-custom-menu.contextual-links-region {
    position: static;
  }

  /*------------------------------------*\
    .mainmenu
  \*------------------------------------*/
  .mainmenu {
    @apply tw-h-full 
    tw-flex 
    tw-items-center;

    // Increase hight of custom menu to avoid mouse leave the menu
    &.block-koda-custom-menu {
      align-self: stretch;
      height: 105px;
    }

    @include less-than($screen-lg) {
      display: none;
    }

    .menu {
      display: flex;
      > li {
        margin-right: 2rem;
        position: relative;
      }
    }
  }
  .mainmenu {
    min-height: 100%;
    position: relative;
    font-weight: 500;

    .nav {
      padding: 0;
      margin: 0;
      background: none;
      position: static;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      box-sizing: border-box;
      margin-bottom: -1px;
      li {
        display: flex;
        box-sizing: border-box;
        a {
          display: flex;
          font-size: 15px;
          border-bottom: none;
          white-space: normal;
          align-items: center;
          justify-content: space-between;
          &.active {
          }
          &:hover {
            color: #dc7b1f;
          }
        }
        &.dropdown {
          > a {
            display: flex;
            align-items: center;
            &:after {
              content: '\f078';
              font-size: 8px;
              line-height: 15px;
              font-family: $font-awesome;
              padding-left: 5px;
            }
          }
        }

        > .dropdown-menu {
          padding: 1rem;
          margin: 0;
          display: block;
          position: absolute;
          height: auto;
          left: 0;
          top: 37px;
          z-index: 99;
          border: none;
          box-sizing: border-box;
          overflow-x: visible;
          visibility: hidden;
          opacity: 0;
          transition: 0.1s 0.1s;
          background: #fff;
          border: 1px solid #e5e5e5;
          min-width: 250px;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          border-top: none;
          li {
            padding: 0;
            width: 100%;
            display: block;
            position: relative;
            a {
              border-bottom: 1px solid rgba(255, 255, 255, 0.15);
              padding: 0.25em 1rem;
              text-transform: none;
              font-size: 15px;
            }
            &:hover {
              > a {
                position: relative;
              }
            }
            &.expanded {
              > a {
                &:after {
                  content: '\f054';
                  font-size: 8px;
                  font-family: $font-awesome;
                }
              }
            }
          }
          .dropdown-submenu {
            > .dropdown-menu {
              left: 105%;
              top: -11px;
            }
          }
        }
        &.open {
        }
        &:hover {
          > .dropdown-menu {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }
    }
  }

  /*------------------------------------*\
    Search
  \*------------------------------------*/
  .search-btn {
    font-size: 2.5rem;
    color: $color-primary;
    @include larger-than($screen-sm) {
      display: none;
    }
  }

  .block-header-search {
    @apply tw-pl-2 
    tw-pr-2 
    tw-text-right;
    flex: 1;
    width: 40%;
    margin-right: auto;
    .search-close {
      display: none;
    }

    /** Autocomplete Dropdown**/
    .views-exposed-widget {
      position: relative;
      .dropdown {
        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 50;
          display: none;
          float: left;
          min-width: 160px;
          padding: 5px 0;
          margin: 2px 0 0;
          list-style: none;
          text-align: left;
          background-color: #fff;
          border: 1px solid #d4d4d4;
          border-radius: 3px;
          background-clip: padding-box;

          > li {
            > a {
              display: block;
              padding: 4px 20px;
              clear: both;
              color: #000;
              font-size: 1.6rem;
              font-weight: normal;
              white-space: nowrap;
              &:hover {
                background-color: $color-primary;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .views-widget {
      position: relative;
    }

    @include less-than($screen-sm) {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 99;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      line-height: 1;
      background-color: rgba(0, 0, 0, 0.88);
      display: none;

      &.showform {
        display: flex;
      }

      .search-close {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        font-size: 2.5rem;
        cursor: pointer;
        padding: 1rem;
        .fa {
          font-weight: normal;
        }
      }

      form {
        width: 80vw;
      }
    }

    @include larger-than($screen-xl) {
      flex: 0 0 45%;
    }

    @include less-than($screen-xl) {
      #edit-search-api-views-fulltext-wrapper {
        width: 100px;
      }
    }

    .views-exposed-form {
      .views-exposed-widgets {
        @apply tw-flex tw-mb-0;

        #edit-search-api-views-fulltext-wrapper {
          flex: 1;
        }

        .views-submit-button {
          width: auto;
          > button {
            background-color: $color-primary;
            height: 50px;
            border-radius: 0 30px 30px 0;
            padding: 0 5px;
            color: transparent;
            position: relative;
            min-width: auto;

            &:before {
              position: absolute;
              content: '\f002';
              font-family: $font-awesome;
              color: #fff;
              left: 50%;
              top: 55%;
              transform: translate(-50%, -50%);
              font-size: 24px;
            }
          }
        }

        .views-exposed-widget {
          @apply tw-flex;
          @apply tw-p-0;
          @apply tw-m-0;

          &#edit-search-api-views-fulltext-wrapper {
            > .views-widget {
              width: 100%;
            }
          }

          .form-group {
            background-color: #efefef;
            border-radius: 30px 0 0 30px;
            overflow: hidden;
            display: flex;

            input[type='text'] {
              background: transparent;
              font-size: 14.8px;
              padding: 14px 25px;
              flex: 1;
              height: 50px;
            }
          }
        }
      }
    }

    .views-submit-button {
      .form-submit {
        margin-top: 0;
      }
    }
  }

  /*------------------------------------*\
    Quote
  \*------------------------------------*/
  .request-quote {
    @apply tw-mx-4;
    min-width: auto;

    li a {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      align-items: center;
      text-align: center;
      width: 6rem;
      &:hover {
        color: $color-primary;
      }
      &:before {
        content: '\f573';
        font-family: $font-awesome;
        font-size: 27px;
        font-weight: 200;
      }
    }

    @include less-than($screen-md) {
      ul li a {
        font-size: 10px;
      }
    }

    @include less-than($screen-lg) {
      margin-left: 0;
      li {
        a {
          font-size: 13px;
          &:before {
            font-size: 24px;
          }
        }
      }
    }

    @include larger-than($screen-lg) {
      li {
        a {
          width: 100%;
        }
      }
    }
  }

  /*------------------------------------*\
    Cart
  \*------------------------------------*/
}

.d-login {
  display: none;
}

.d-logout {
  display: block;
}

.logged-in {
  .d-login {
    display: block;
  }

  .d-logout {
    display: none;
  }
}
