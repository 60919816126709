/*------------------------------------*\
  File: Common
\*------------------------------------*/

.read-more,
.read-less {
  position: relative;
  display: block;
  font-weight: bold;
  color: $color-primary;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;

  &::after {
    font-family: $font-awesome;
    margin-left: 8px;
  }
}

.read-more {
  &::after {
    content: '\f107';
  }
}

.read-less {

  &::after {
    content: '\f077';
  }
}
