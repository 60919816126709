/*------------------------------------*\
  Specials
\*------------------------------------*/

.specials {
  .view-content {
    .node-teaser {
      &.node-product {
        position: relative;
        @include product-card;
        .product-info {
          .uc-price {
            &::before {
              content: 'From ';
              font-size: 1.4rem;
            }
          }
        }
        @include less-than($screen-lg) {
          padding-bottom: 2rem;
          border-bottom: 1px dashed #d7d7d7;
        }
      }
    }
  }
}
