.page-products {
  main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;

    @include less-than($screen-sm) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  .main-content {
    flex: 1;

    @include less-than($screen-sm) {
      width: 100%;
      padding-left: 0 !important;
    }

    .region-content {
      display: flex;
      flex-direction: column;

      .block-search-api-sorts {
        order: -1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        h2 {
          font-weight: normal;
          font-size: 15px;
          letter-spacing: 0.02em;
          color: #000000;
          margin-bottom: 0;

          &:after {
            content: ":";
          }
        }

        select {
          background: #ffffff;
          margin-left: 2rem;
          border: 1px solid #acacac;
          box-sizing: border-box;
          font-weight: normal;
          font-size: 15px;
          letter-spacing: 0.02em;
          color: #000000;
          padding: 4px 1rem;
        }
      }

      .view-product-search-api {
        .view-content {
          @include grid-layout(4, 5rem);

          @include less-than($screen-xl) {
            @include grid-layout(3, 2rem);
          }

          @include less-than($screen-lg) {
            @include grid-layout(2, 2rem);
          }

          @include less-than($screen-md) {
            @include grid-layout(1, 0rem);
            .views-row {
              margin-bottom: 2rem;
            }
          }

          .node-product {
            @include product-card;
          }
        }
      }
    }
  }
}

