/*------------------------------------*\
  Taxonomy
\*------------------------------------*/
.page-taxonomy-term {
  main {
    margin-bottom: 8rem;
  }

  .taxonomy-term-description{
    > p{
      &:not(:last-child){
        margin-bottom: 1rem;
      }
    }
  }

  .block-system {
    display: flex;
    flex-wrap: wrap;
  }

  .term-listing-heading {
    flex-basis: 100%;

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 156.69%;
      color: #575757;
    }
  }

  .node-product.node-teaser{
    flex-basis: 25%;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 42rem;

    @include less-than($screen-xl) {
      flex-basis: calc(100% / 3);
    }

    @include less-than($screen-lg) {
      flex-basis: 50%;
    }

    @include less-than($screen-md) {
      flex-basis: 100%;
    }
  }

  .text-center {
    flex-basis: 100%;
    margin: 2rem auto;

    ul {
      display: flex;
      justify-content: center;

      li {
        margin-right: 1rem;

        &.active {
          color: $color-primary;
        }
      }
    }
  }

  .view-content {
    @include grid-layout(4, 0);

    @include less-than($screen-xl) {
      @include grid-layout(3, 0);
    }

    @include less-than($screen-lg) {
      @include grid-layout(2, 0);
    }

    @include less-than($screen-md) {
      @include grid-layout(1, 0);
      .views-row {
        margin-bottom: 2rem;
      }
    }

    .views-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;
      border-top: 1px dashed #f0f0f0;
      border-left: 1px dashed #f0f0f0;
      
      @include larger-than($screen-xl) {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),{
          border-top: none;
        }
        &:first-child,
        &:nth-child(4n+1){
          border-left: none;
        }       
      }

      //@include less-than($screen-xl) {
      @media all and (max-width:$screen-xl) and (min-width:$screen-lg) {  
        &:first-child,
        &:nth-child(2),
        &:nth-child(3){
          border-top: none;
        }
        &:first-child,
        &:nth-child(3n+1){
          border-left: none;
        } 
      }

      @media all and (max-width:$screen-lg) and (min-width:$screen-md) {  
        &:first-child,
        &:nth-child(2){
          border-top: none;
        }
        &:first-child,
        &:nth-child(2n+1){
          border-left: none;
        }
      }
      @include less-than($screen-md) {
        border-left: none;
      }


    }
  }

}
