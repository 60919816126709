.blog--view-cont {
  .view-content {
    .views-row {
      .field-name-field-image {
        img {
          width: 100%;
          height: 225px;
          object-fit: cover;
        }
      }

      .field-name-post-date {
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        color: #8e8e8e;
        margin-top: 15px;
      }

      .field-name-title {
        h2 {
          font-size: 18px;
          font-weight: 600;
          margin: 7px 0;
          color: #585858;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #585858;
      }

      .field-name-node-link {
        margin-top: 1rem;
        a {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: $color-primary;

          &::after {
            content: '\f061';
            margin-left: 1rem;
            line-height: 24px;
            font-family: $font-awesome;
          }
        }
      }
    }
  }
}
//full content
.node-type-article {
  .page-header {
    font-size: 40px;
    font-weight: 600;
    color: #2b2b2b;
  }

  .view-mode-full {
    .field-name-field-image {
      img {
        float: right;
        margin-left: 5rem;
        margin-bottom: 5rem;
        object-fit: contain;
        height: auto;
        max-height: 439px;
        max-width: 50%;

        @include less-than($screen-md) {
          max-width: 100%;
          float: none;
          margin: 0 auto;
          margin-bottom: 2rem;
        }
      }
    }
    .field-name-body {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        color: #585858;
      }
      h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #585858;
      }
    }
    .field-name-post-date {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: #585858;
      margin-bottom: 2rem;
    }
  }
}
