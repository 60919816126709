/*------------------------------------*\
    Component: Button
\*------------------------------------*/
@mixin button() {
  border-radius: 85px;
  background: #9b9b9b;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 8px 20px;
  transition: background 0.3s;
  min-width: 16.8rem;
  align-items: center;

  &:hover {
    background-color: lighten(#9b9b9b, 10%);
  }
}

@mixin button-color($color) {
  background: $color;
  &:hover {
    background-color: lighten($color, 10%);
  }
}

.button {
  @include button;
}

.button-primary {
  @include button-color($color-primary);
}

.button-success {
  @include button-color($color-green);
}

.button-square {
  border-radius: 3px;
}

@mixin btn($bgColor: $color-primary) {
  border-radius: 85px;
  font-size: 16px;
  border: 1px solid $bgColor;
  font-weight: bold;
  transition: all 0.3s;
  display: inline-flex;
  min-width: 16.8rem;
  padding: 5px;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: $bgColor;

  i {
    margin-right: 1rem;
    font-size: 1.5rem;
  }

  &:focus,
  &:active,
  &:hover {
    background: lighten($bgColor, 5%);
    border-color: darken($bgColor, 2%);
    text-decoration: none;
    outline: none;
  }
}
