/*------------------------------------*\
    Page: Home
\*------------------------------------*/
.front {
  .content {
    > .tw-container {
      max-width: unset;
    }
  }
}

.category-links-block {
  padding-bottom: 3rem;
  margin-bottom: 1rem;
  @include container;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    width: 100vw;
    height: 100%;
    left: calc(0px - (100vw - 100%) / 2);
    z-index: -1;
    visibility: visible;
    border-bottom: 1px solid #eaeaea;
  }

  ul {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    flex-wrap: wrap;

    li {
      padding-bottom: 2rem;

      a {
        overflow: visible;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        flex: 0 0 50%;
        @include imgZoomHover;

        img {
          margin-right: 1rem;
          margin-bottom: 1rem;
        }

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  @include larger-than($screen-md) {
    ul li {
      flex: 0 0 33.3%;
    }
  }

  @include larger-than($screen-xl) {
    margin-bottom: 5rem;
    ul li {
      padding-bottom: 0;
      flex: 1;
      a {
        flex-direction: row;
        img {
          margin-bottom: 0;
        }
      }
    }
  }
}

.home-intro-block {
  display: flex;
  margin: 8rem auto;
  @include container;

  .col-left {
    width: 45%;

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 156.69%;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #afafaf;
    }

    h2 {
      font-weight: 600;
      font-size: 60px;
      line-height: 104.19%;
      color: #2b2b2b;
      margin-top: 2rem;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 156.69%;
      color: #575757;
      margin-bottom: 3rem;
    }

    @include less-than($screen-md) {
      h2 {
        font-size: 30px;
      }
    }
  }

  .col-right {
    width: 55%;
    padding-left: 7%;
  }

  @include less-than($screen-lg) {
    margin: 2rem auto;
    flex-wrap: wrap;

    .col {
      flex-basis: 100%;

      &.col-right {
        padding-left: 0;
        margin-top: 5rem;
      }
    }
  }
}

.home-testimonials {
  background-image: url('/sites/all/themes/kodaweb_new/images/home-testimonials-bg.jpg');
  padding: 6rem;

  @include less-than($screen-md) {
    padding: 3rem;
  }

  .view-home-testimonial-slide {
    position: relative;

    .slick-track {
      display: flex;
      align-items: stretch;
    }

    .slide__content {
      display: flex;
    }

    .node-testimonial {
      height: 100%;
      margin: 0 2rem;
      background: rgba(255, 255, 255, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 4rem;
      color: #fff;

      .slick-track {
        display: flex;
        align-items: stretch;
      }

      @include less-than($screen-md) {
        padding: 2rem;
      }

      /** Teaser **/
      &.view-mode-teaser {
        > h4 {
          font-size: 25px;
          > b {
            padding-left: 0.5rem;
          }
        }
      }
      .field-name-testimonial-title {
        p {
          font-weight: normal;
          font-size: 25px;
          line-height: 104.19%;
        }
      }

      .field-name-body {
        margin: 2rem 0 3rem 0;
        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 156.69%;
          width: 100%;
        }
      }

      .field-name-node-link {
        @extend .button;
        @extend .button-primary;
        display: inline-block;
        margin-top: auto;
        a {
          display: flex;
          align-items: center;

          &:after {
            content: '\f178';
            font-family: $font-awesome;
            line-height: 1;
            font-weight: 200;
            margin-left: 1rem;
            margin-top: 3px;
          }
        }
      }
    }
  }

  .slick-arrow {
    --slickArrowOffset: -5rem;
    @include less-than($screen-md) {
      --slickArrowOffset: -3rem;
    }

    &.slick-prev {
      left: var(--slickArrowOffset);
    }

    &.slick-next {
      right: var(--slickArrowOffset);
    }
  }
}

.slick {
  &--optionset--five-column-carousel {
    @apply tw-w-full;
    .slick-track {
      @apply tw-flex tw-w-full;
      > .slide {
        @apply tw-px-8 tw-h-auto;
      }
    }

    .slick {
      &__arrow {
        .slick-arrow {
          border-radius: 0;
          width: auto;
          &::before {
            line-height: 2;
            color: $color-primary;
          }
        }
      }
    }
  }

  &--view--home-featured-categories {
    .slick-track {
      > .slide {
        border-left: 1px dashed #d7d7d7;
        &.slick-current {
          border-left: none;
        }
      }
    }
  }
}

.home-featured-categories {
  margin-bottom: 4rem;
  @include container;

  @include larger-than($screen-md) {
    margin-bottom: 8rem;
  }

  @include block-title;

  .view-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;

    .views-row {
      flex-basis: 20%;
      padding: 2rem;

      @include less-than($screen-lg) {
        flex-basis: 100%;
      }

      &:not(:last-child) {
        border-right: 1px dashed #d7d7d7;

        @include less-than($screen-lg) {
          border: none;
          border-bottom: 1px dashed #d7d7d7;
        }
      }
    }

    .vocabulary-category {
      .field-name-field-category-image {
        @include imgZoomHover;
        img {
          height: 225px;
          object-fit: contain;
          margin: 0 auto;
        }
      }

      .field-name-title {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;

        a {
          font-weight: 600;
          font-size: 22px;
          line-height: 138.69%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #3f3f3f;
        }
      }

      .field-name-field-short-description {
        font-weight: normal;
        font-size: 16px;
        line-height: 134.69%;
        text-align: center;
        color: #575757;
      }
    }
  }
}

.home-special-list {
  margin: 2rem auto;
  @include container;
  @include block-title;

  @include larger-than($screen-md) {
    margin: 8rem auto;
  }

  .view-content {
    margin-top: 4rem;

    .node-product {
      &.node-product {
        &:before {
          content: 'Sale!';
          position: absolute;
          width: 48px;
          height: 48px;
          border-radius: 100%;
          background: $color-primary;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 0;
          top: 0;
          z-index: 50;
          font-style: italic;
        }
      }
    }
  }
}
