.top-menu {
  height: auto;
  background-color: #505050;

  @include less-than($screen-md){
    &__slogan{
      display: none;
    }
  }

  .region.region-top-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    min-height: 40px;
    padding-top: 4px;
    padding-bottom: 4px;

    ul {
      &:not(.contextual-links){
        display: flex;
        li {
          margin-left: 1rem;
          a {
            font-size: 14px;
          }
        }
      }

    }
  }
}
