/*------------------------------------*\
  Testimonials
\*------------------------------------*/


/** Node **/
.node-testimonial{

    /** Teaser 2 **/
    &.view-mode-teaser_2{
        color: #3F3F3F;
        .field-name-body{
            font-size: 14px;
            color: #3F3F3F;
            p{
                line-height: 1.8;
            }
        }
        .field-name-author{
            margin-top: 2rem;
            font-weight: 600;
        }
    }
    /** Full **/
    &.view-mode-full{
        .field-name-author{
            margin-top: 2rem;
            font-weight: 600;
        }
    }
}


/** View Page **/
.page-testimonials{
    .page_header{
        margin-bottom: 4rem;
    }

    .view-testimonials{
        .view-content{
            .views-row{
                position: relative;
                background: #FFFFFF;
                border: 1px solid #E1E1E1;
                box-sizing: border-box;
                box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
                margin-bottom: 1rem;
                padding: 3rem;
                width: calc(100% - 4rem);
                margin-left: 2rem;
                margin-right: 2rem;  
                margin-bottom: 4rem;
                &::before{
                    content: url('../../images/icon--quote.svg');
                    position: absolute;
                    top: 0;
                    margin-top: -20px;
                }
            }
            @include larger-than($screen-sm){
                margin-left: -2rem;
                margin-right: -2rem;
                .views-row{
                    width: calc(50% - 4rem);
                }
            }
            @include larger-than($screen-lg){
                .views-row{
                    width: calc(33.3% - 4rem);
                }
            }
        }
    }
}

