.page-cart-checkout {
  table.sticky-header {
    display: none; // Remove this feature
  }

  table.cart-review {
    @include cart-table;

    thead {
      > tr {
        th.products {
          text-align: left;
        }
      }
    }
  }

  .block-system {
    fieldset:first-child {
      legend {
        display: none;
      }
    }

    fieldset:not(:first-child) {
      border-top: 2px solid #dc7b1f;
      padding: 2rem 0;

      legend {
        float: left;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 1rem;
      }

      .panel-body {
        clear: both;
        font-weight: normal;
        font-size: 14px;
        line-height: 158.8%;
        color: #616161;

        td {
          padding: 1rem 0;
        }

        .field-label,
        label {
          width: 100%;
          display: inline-block;
          font-weight: normal;
          font-size: 14px;
          line-height: 140.62%;
          letter-spacing: 0.02em;
          color: #474747;
          text-align: left;
          padding: 1rem 0;
        }

        input,
        select {
          background: #ffffff;
          border: 1px solid #d0d0d0;
          padding: 5px;
          width: 100%;
        }

        .chosen-container-single {
          width: 100% !important;
          .chosen-single {
            width: 100%;
            height: 35px;
            display: flex;
            align-items: center;
            border-radius: 0;
          }
        }

        @include larger-than($screen-md) {
          .field-label,
          label {
            width: 135px;
          }
          input,
          select {
            width: 450px;
          }
        }
      }
    }

    .form-type-textarea {
      display: flex;
      flex-direction: column;
      > .control-label {
        width: auto !important;
      }
      .form-control {
        padding: 1rem;
        width: 100%;
        border: 1px solid #d0d0d0;
      }
    }

    #delivery-pane-body {
      .form-item-panes-delivery-select-address {
        margin-bottom: 1rem;
        display: flex;
        @include less-than($screen-md) {
          flex-direction: column;
        }
      }
    }

    #delivery-address-pane {
      table {
        tr {
          background: unset;
        }
      }

      @include less-than($screen-md) {
        table {
          width: 100%;
          tr,
          thead,
          th,
          tbody,
          td {
            width: 100%;
          }

          td {
            padding: 0;
            &.field-label {
              margin-bottom: 1rem;
            }
          }

          tr {
            margin-bottom: 1rem;
          }
        }
      }
    }

    #payment-pane {
      position: relative;
      div[id*='payment-pane-body'] {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .help-block {
          flex: 0 0 100%;
        }

        .form-item-panes-payment-payment-method {
          order: 0;
        }
        #line-items-div {
          order: 1;
          margin-left: auto;
        }
        #payment-details {
          order: 2;
          font-size: 14px;
          font-weight: bold; // as default

          P,
          a,
          ol,
          ul {
            font-weight: normal;
            font-size: inherit;
          }

          p,
          ol,
          ul {
            margin-bottom: 1rem;
          }

          &.payment-details-uc_stripe_extra {
            #card-element {
              padding: 1rem;
              background-color: #fafafa;
              border: solid 1px #bbb;
              border-radius: 4px;

              @include larger-than($screen-md) {
                width: 58.5rem;
              }
            }
          }
        }
      }

      #uc-order-total-preview {
        tr {
          width: 100%;
          td {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }
      }

      #line-items-div {
        //position: absolute;
        right: 0;

        @include less-than($screen-md) {
          position: relative;
          margin: 0;
          margin-top: 1rem;
        }
      }

      .form-item.radio {
        label {
          display: flex;
          align-items: center;
          width: 100%;

          input[type='radio'] {
            width: 30px;
            min-width: 30px;
          }
        }
      }
    }

    #payment-details {
      margin-top: 2rem;
      width: 100%;
    }

    #comments-pane {
      textarea {
        background: #ffffff;
        border: 1px solid #cccccc;
      }
    }

    #quotes-pane {
      .btn {
        color: $color-primary;
        font-weight: bold;
      }

      #quote {
        @apply tw-border-none
        tw-p-0;
        label {
          @apply tw-w-full
          tw-flex
          tw-py-2
          tw-items-center
          tw-justify-start;
          input {
            @apply tw-w-auto 
            tw-mx-4;
          }
        }
      }
    }

    .form-actions {
      button {
        @extend .button;
        @extend .button-square;
      }

      #edit-cancel {
        @extend .button-primary;
      }

      #edit-continue {
        @extend .button-success;
      }
    }
  }

  .help-block {
    margin-bottom: 1.5rem;
  }

  // Cart Checkout form
  form {
    &.uc-cart-checkout-form {
      #customer-pane-body {
        .help-block {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
