/*------------------------------------*\
  File: Sharethis buttons
\*------------------------------------*/
.block-sharethis {
  .sharethis-wrapper {
    display: flex;
    &:before {
      content: "Share:";
      font-weight: 500;
    }

    span {
      border-radius: 100%;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      background-color: #dadada;
      margin-left: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken(#dadada, 5%);
      }

      &:after {
        content: "";
        color: #fff;
        font-family: $font-awesome-brands;
      }

      &[class*="facebook"] {
        &:after {
          content: "\f39e";
        }
      }

      &[class*="twitter"] {
        &:after {
          content: "\f099";
        }
      }

      &[class*="email"] {
        &:after {
          font-family: $font-awesome;
          content: "\f0e0";
          font-weight: bold;
        }
      }

      &[class*="linkedin"] {
        &:after {
          content: "\f0e1";
        }
      }

      &[class*="sharethis"] {
        &:after {
          font-family: $font-awesome;
          content: "\f1e0";
          font-weight: bold;
        }
      }
    }
  }
}
