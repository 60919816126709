.page-node.about {
  main {
    margin-bottom: 8rem;
  }

  .region-content {
    display: flex;
    color: #575757;
    font-size: 16px;
    @include less-than($screen-lg) {
      flex-direction: column;
    }

    h2 {
      margin-top: 2rem;
    }

    .button {
      margin-top: 2rem;
      display: inline-block;
    }

    .block-system {
      flex-basis: 50%;
      @include less-than($screen-lg) {
        flex-basis: 100%;
        margin-bottom: 4rem;
      }
    }

    .service-block {
      flex-basis: 40%;
      padding-left: 5rem;
      @include less-than($screen-lg) {
        flex-basis: 100%;
        margin-bottom: 4rem;
      }

      .service-content {
        background-color: #383838;
        color: #fff;
        padding: 3rem;
        width: 70%;
        float: right;

        @include less-than($screen-lg) {
          width: 100%;
        }

        h3 {
          font-weight: bold;
          font-size: 23px;
          line-height: 139.69%;
        }

        ul {
          @extend .unorder-list;

          li {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .director-block {
      padding-left: 2rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      
      &__nzo{
        position: relative;
        max-width: 17.8rem;
        display: flex;
        align-items: center;
        flex-direction: column;  
        color: #FFF;
        font-size: 2.3rem;
        font-weight: bold;
        margin-bottom: 2rem;
        padding: 2rem;
        
        &::before{
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 65%;
          z-index: -1;
          background-color: #DC7B1F;
        }
      }
      &__director{
        max-width: 17.8rem;
        img {
          margin-bottom: 2rem;
        }
      }

      @include less-than($screen-lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
