/*------------------------------------*\
    Footer
\*------------------------------------*/
.footer {
  padding: 4rem 0;
  margin-bottom: 2rem;

  @include less-than($screen-md) {
    padding-top: 0;
    margin-top: 1rem;
    .region-footer {
      flex-direction: column;
      text-align: center;
    }
  }

  .region-footer {
    display: flex;
    color: #4a4a4a;
    font-size: 14px;
    padding-top: 4rem;
    border-top: 1px solid #f0f0f0;

    > section {
      flex-basis: calc(100% / 6);

      @include less-than($screen-md) {
        flex-basis: 100%;
        margin-bottom: 4rem;
      }

      h2 {
        font-weight: 600;
        font-size: 16px;
      }

      ul {
        li {
          margin-bottom: 1rem;
        }
        a {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }

    .copyright {
      margin-right: 4rem;
      img {
        width: 160px;
        margin-bottom: 2rem;
      }

      p {
        font-size: 14px;
      }

      @include less-than($screen-md) {
        margin-right: 0;
        img{
          margin-left: auto;
          margin-right: auto;
        }

      }
    }

    .product-menu {
      flex: 1;
    }

    .footer-contact {
      .social-media-link {
        width: 33px;
        height: 33px;
        background-color: $color-primary;
        color: #fff;
        display: inline-block;
        border-radius: 100%;
        position: relative;
        margin-right: 1rem;

        i {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
