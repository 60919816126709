/*------------------------------------*\
    Base
\*------------------------------------*/

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  width: 100%;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

:focus {
  outline: none;
}

h1,
h2 {
  font-weight: bold;
}

.unorder-list {
  list-style: disc;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.field-type-text-with-summary {
  ul {
    @extend .unorder-list;
  }
}
