/*------------------------------------*\
  Hamburger
\*------------------------------------*/


.hamburger {
  @apply tw-relative 
  tw-cursor-pointer 
  tw-mx-4;
  padding: 10px 0;
  @include larger-than($screen-lg) {
    @apply tw-hidden;
  }

  span {
    background: $color-primary;
    display: block;
    height: 4px;
    margin: 6px 0;
    border-radius: 0;
    width: 33px;
    position: relative;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:last-child {
      transform-origin: 0% 100%;
    }
  }

  &.is-active span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);

    &:nth-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
}

html.mm-opening .header {
  .hamburger span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
    cursor: pointer;

    &:nth-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
}

/*------------------------------------*\
  File: Slide Menu
\*------------------------------------*/
.slide_menu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 36rem;
  height: 100vh;
  z-index: 99;
  background-color: #3f3f3f;
  color: #fff;
  padding: 4rem 2.5rem;
  transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translateX(-100%);
  text-align: center;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .hamburger{
    @apply tw-mx-0;
  }

  .region-slide-menu {
    position: relative;
  }

  .slide_menu_trigger {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: #b4b4b4;
    transition: all 0.3s;
    font-size: 2rem;

    &:hover {
      color: #fff;
    }
  }

  .block-title {
    display: none;
  }

  .menu-block-wrapper{

    .menu {
      > li {
        border-top: 1px dashed rgba(255, 255, 255, 0.2);
        text-align: left;
        cursor: pointer;
        &.dropdown {
          > a {
            display: flex;
            align-items: center;
            .caret {
              margin-left: 1rem;
              display: inline-block;
              transition: all 0.3s;
              position: relative;
              z-index: 99;
              &:before {
                content: '\f107';
                font-family: 'fontawesome';
                font-size: 15px;
              }
            }
          }
        }

        &.opened {
          .caret {
            transform: rotate(180deg);
          }
        }

        a {
          display: inline-block;
          padding: 2rem 0 0.8rem;
          font-size: 1.6rem;
          transition: all 0.3s;
          width: fit-content;
          &:hover {
            color: $color-primary;
          }
        }

        ul.dropdown-menu {
          margin-left: 2rem;
          padding-bottom: 2rem;
          display: none;
        }
        &.open {
          > .dropdown-menu {
          display: block;
            .dropdown-submenu {
              > a {
                  display: flex;
                  align-items: center;
                &:after {
                  content: '\f078';
                  font-family: 'fontawesome';
                  font-size: 13px;
                  margin-left: .5rem;
                }
              }
              
              &.open {
                > .dropdown-menu {
                  display: block;
                }
                > a {
                  &:after {
                    content: '\f054';
                    font-family: 'fontawesome';
                    font-size: 13px;
                    margin-left: .5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


/*------------------------------------*\
  Slide Menu - Opened
\*------------------------------------*/
.slide_menu-opened {

  .slide_menu {
    transform: translateX(0);
    overflow-y: scroll;
  }

  .slide_menu_layer {
    opacity: 1;
    visibility: visible;
  }
}


.slide_menu_layer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 98;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
}

