.subfilter__main-cont {

	.subterm_filter_list {
		display: flex;
		flex-wrap: wrap;
		li{
			display: flex;
		    border: 2px solid #dc7b1f;
		    align-items: center;
		    justify-content: center;
		    margin-right: 10px;
		    margin-bottom: 10px;
		    border-radius: 5px;
		    box-shadow: 2px 1px 4px 0 #d9d0d0;
		    a {
				display: flex;
			    justify-content: center;
			    align-items: center;
			    padding: 0.5rem 1.5rem;
			    color: #dc7b1f;
				img {
					width: 20px;
	    			margin-right: 10px;
				}
				p {
					font-size: 1.4rem;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			&:hover {
				border-color: #e7e7e7;
				border-width: 2px;
			}
		}
		
	}
}