@mixin container {
  position: relative;
  max-width: 92%;
  margin-left: auto;
  margin-right: auto;

  @include larger-than($screen-xxl) {
    max-width: $screen-xxl;
  }

  @include less-than($screen-xxl) {
    max-width: $screen-xl;
  }

  @include less-than($screen-xl) {
    width: 100%;
    padding: 0 1rem;
  }

  // @include less-than($screen-lg) {
  //   max-width: $screen-md;
  // }

  // @include less-than($screen-md) {
  //   max-width: $screen-sm;
  //   //margin-top: 1rem;
  // }

  // @include less-than($screen-sm) {
  //   width: 100%;
  //   padding: 0 1rem;
  // }
}

.tw-container {
  @include container;
}

.sidebar-first {
  .content {
    .sidebar {
      display: block;
    }

    @include larger-than($screen-md){
      .main-content {
        padding-left: 3rem;
      }
    }

  }
}

main {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.content {
  .content-wrapper {
    display: flex;
    justify-content: center;

    .region-content {
      width: 100%;
      flex-grow: 1;
    }

    .region-content-right {
      flex-basis: 25%;
      width: 25%;
      padding-left: 4rem;
    }

    @include less-than($screen-lg) {
      flex-direction: column;

      .region-content-right {
        flex-basis: 100%;
        width: 100%;
        padding-left: 0;
        margin-top: 5rem;
      }
    }
  }

  @include less-than($screen-sm) {
    margin: 0;
  }

  .breadcrumb {
    margin: 1rem 0 .5rem;
  }

  .sidebar {
    display: none;
    .view-footer-category-links {
      display: none;
    }
  }
}

