/*------------------------------------*\
  React Mobile Menu
\*------------------------------------*/
#app-custom-menu-2 {
  text-align: left;
  a:hover,
  .top-menu-link:hover,
  .item-name:hover {
    cursor: pointer;
    color: $color-primary;
  }

  .back-link {
    display: none;
  }

  .img-wrapper {
    display: none;
  }

  .has-children {
    > span,
    > a {
      &:after {
        content: '\f107';
        font-family: $font-awesome;
        font-weight: normal;
        margin-left: 1rem;
      }
    }
    > .react-menu-dropdown {
      padding-left: 15px;
    }
  }

  a {
    color: #fff;
  }
  .selected {
    //background: rgb(25, 56, 96);
  }

  .parent-item ~ .this-items {
    padding-left: 15px;
  }

  .react-menu-item:not(:last-child):not(.selected) {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  }

  .react-menu-option,
  .react-menu-item {
    padding: 14px 0;
    font-size: 16px;
    font-weight: 500;
  }
  .react-menu-option {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
    > a {
      display: flex;
    }
  }
}
