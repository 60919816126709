/*------------------------------------*\
  Views
\*------------------------------------*/

.view-five-columns{
  .view-content {
      @include grid-layout(5, 5rem);
      @include less-than($screen-xl) {
        @include grid-layout(4, 2rem);
      }
      @include less-than($screen-lg) {
        @include grid-layout(2, 2rem);
      }
      @include less-than($screen-md) {
        @include grid-layout(1, 0rem);
        .views-row {
          margin-bottom: 2rem;
        }
      }
  }
}

.view-four-columns{
    .view-content {
        @include grid-layout(4, 5rem);
        @include less-than($screen-xl) {
          @include grid-layout(3, 2rem);
        }
        @include less-than($screen-lg) {
          @include grid-layout(2, 2rem);
        }
        @include less-than($screen-md) {
          @include grid-layout(1, 0rem);
          .views-row {
            margin-bottom: 2rem;
          }
        }
    }
}

.view-three-columns{
    .view-content {
        @include grid-layout(3, 5rem);
        @include less-than($screen-lg) {
          @include grid-layout(2, 2rem);
        }
        @include less-than($screen-md) {
          @include grid-layout(1, 0rem);
          .views-row {
            margin-bottom: 2rem;
          }
        }
    }
}

