.banner {

  > .tw-container {
    padding: 0;
    max-width: unset;
    //height: 320px;
    img {
      width: 100%;
      height: 320px;
      object-fit: cover;
      object-position: center;
    }

    .slider-link{
      > a{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        color: transparent;
        font-size: 0;
      }

    }
  }

  .slick-arrow {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .field-name-title{
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 5rem;
    padding-bottom: 3rem;
    width: 100%;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 94.73%);

    .field-item{
      @extend .tw-container;
    }

    h2{
      width: 100%;
      color: #FFF;
      font-size: 3rem;
      line-height: 1.1;
    }

  }

  @include larger-than($screen-md){
    .field-name-title{
      padding-bottom: 5rem;
      h2{
        font-size: 5.5rem;
        margin-bottom: 0;
      }
    }
  }

  @include larger-than($screen-sm){ 
    .tw-container {
      img {
        height: 620px;
      }
    }
  }

}
