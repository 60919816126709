@mixin cart-table {
  @include less-than($screen-md) {
    display: flex;
    flex-direction: column;

    thead {
      display: none;
    }

    tbody,
    tr {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    tbody {
      flex-direction: column;
    }

    tr {
      width: 100%;

      > td {
        position: relative;
        display: flex;
        text-wrap: wrap;

        &.image {
          justify-content: center;
          width: 100% !important;
          flex: 0 0 100% !important;
          flex-basis: 100% !important;
        }

        &.desc {
          width: 100% !important;
          flex: 0 0 100% !important;
          flex-basis: 100% !important;
          flex-direction: column;
        }

        &.qty {
          flex: 0 0 50%;
        }

        &.price {
          flex: 1;
          justify-content: flex-end;
        }

        &.remove {
          position: absolute;
          top: 0;
          right: 0;
        }

        &.subtotal {
          width: 100% !important;
          flex: 0 0 100% !important;
          flex-basis: 100% !important;
          justify-content: center;
        }
      }
    }
  }

  thead {
    background: #4a4a4a;

    th {
      font-weight: normal;
      font-size: 16px;
      line-height: 151.69%;
      color: #ffffff;
      padding: 1rem 2rem;
      abbr {
        text-decoration: none;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background: #fafafa;
      }
      &.subtotal {
        background: unset;
      }
    }

    td {
      font-weight: normal;
      font-size: 13px;
      line-height: 180.5%;
      letter-spacing: 0.02em;
      padding: 1rem 2rem;
      color: #3f3f3f;

      &.qty {
        width: 5rem;

        .form-item {
          input {
            height: 28px;
            width: 5rem;
            color: #000;
            border: 1px solid #d2d2d2;
            padding: 0;
            text-align: center;
          }
        }

        .form-type-uc-quantity {
          display: flex;
          align-items: center;

          &.qty-updated {
            position: relative;
            &::before {
              content: '*';
              position: absolute;
              color: $color-primary;
              left: -15px;
              line-height: 1;
            }
            .form-text {
              color: $color-primary;
            }
          }

          .increment {
            outline: none;
            cursor: pointer;
            height: 28px;
            display: flex;
            width: 20px;
            align-items: center;
            justify-content: center;
            border: 1px solid #d2d2d2;
            background-color: #fff;
            font-size: 20px;
            &.plus {
              border-radius: 0 3px 3px 0;
              margin-left: -1px;
            }
            &.minus {
              border-radius: 3px 0 0 3px;
              margin-right: -1px;
            }
            &:hover {
              color: $color-primary;
            }
          }
          > .control-label {
            display: none;
          }
        }
      }

      &.price {
        font-weight: bold;
        font-size: 16px;
        line-height: 164.69%;
        text-transform: uppercase;
        color: #555555;
      }

      &.remove {
        text-align: center;
        button {
          font-size: 0;
          text-align: center;

          &::before {
            content: '\f00d';
            font-size: 16px;
            font-family: $font-awesome;
          }

          &:hover {
            color: $color-primary;
          }
        }
      }

      &.subtotal {
        font-size: 16px;
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: unset;
        border-top: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
      }

      @include less-than($screen-md) {
        &.remove {
          order: 10;
        }

        &.image {
          flex-basis: 140px;
        }

        &.desc {
          flex-basis: calc(100% - 140px);
        }
      }
    }
  }
}

.page-cart {
  .alert {
    strong {
      margin-right: 1rem;
    }
  }

  .order-review-table {
    margin-top: 2rem;
  }

  .block-system {
    margin-bottom: 8rem;

    #uc-cart-view-form-table {
      margin-block: 2rem;
    }
    table {
      @include cart-table;
      tbody {
        tr {
          td {
            vertical-align: middle !important;
          }
        }
      }
    }

    .form-actions {
      a,
      button {
        @extend .button;
        @extend .button-square;
        margin: 0;
        text-align: center;

        @include less-than($screen-md) {
          width: 100%;
          margin-bottom: 2rem;
        }
      }

      a,
      #edit-update-ajax {
        @extend .button-primary;
      }

      button.form-submit {
        @extend .button-success;
      }
    }
  }
}
