/*------------------------------------*\
    Basic Mixins
\*------------------------------------*/
@mixin link {
  color: #dc7b1f;
  transition: color 0.3s;

  &:hover {
    color: lighten(#dc7b1f, 20%);
  }
}

@mixin grid-layout($cols, $gap) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat($cols, minmax(0, 1fr));
}

@mixin squareImgBox {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  box-sizing: border-box;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin less-than($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin larger-than($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin block-title() {
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 110px);
    height: 1px;
    background: #e3e3e3;
    top: 12px;
    right: 0;
  }

  h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 104.19%;
    color: #dc7b1f;
    background-color: #fff;
    display: inline-block;
    position: relative;
    z-index: 10;
  }
}

@mixin product-card() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 4rem;

  .field-name-uc-product-image {
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include imgZoomHover();
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .field-name-title {
    font-weight: normal;
    font-size: 13px;
    line-height: 138.69%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #3f3f3f;
    //height: 52px;
    margin-top: 1rem;
  }

  .field-name-teaser-price {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 149.69%;
    color: #4a4a4a;
    margin: 2rem auto 1rem;
  }

  .product-info {
    margin-top: auto;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    flex-direction: column;
    .uc-price {
      color: #4a4a4a;
      font-weight: bold;
      &:after {
        content: ' + GST';
        font-weight: normal;
        font-size: 1.4rem;
      }
    }

    .uc-promo-price {
      font-size: 14px;
      span {
        font-weight: 600;
      }
    }
  }

  .field-name-node-link {
    a {
      background: #fff;
      border: 2px solid rgba(220, 123, 31, 0.3);
      box-sizing: border-box;
      border-radius: 85px;
      font-style: normal;
      font-weight: bold;
      font-size: 16.939px;
      line-height: 149.69%;
      text-align: center;
      letter-spacing: 0.02em;
      color: $color-primary;
      display: block;
      transition: all 0.3s;
      padding: 5px 0;

      &:hover {
        background: $color-primary;
        color: #fff;
      }
    }
  }
}

@mixin animated($duration: 300ms) {
  transition: all 300ms ease;
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
}

@mixin scale($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -ms-transform: scale($factor);
  -o-transform: scale($factor);
}

@mixin imgZoomHover($zoom: 1.09) {
  @include animated;
  overflow: hidden;
  position: relative;
  display: block;

  img {
    @include animated(1000ms);
  }
  &:hover {
    img {
      @include scale($zoom);
    }
  }
}
