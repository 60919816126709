.page-node.contact {
  .region-content {
    display: flex;
    justify-content: space-between;
    @include less-than($screen-lg) {
      flex-direction: column;
    }

    .block-system {
      display: none;
    }

    .block-webform {
      flex-basis: 50%;
      @include less-than($screen-lg) {
        flex-basis: 100%;
        margin-bottom: 4rem;
      }

      form > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        // input,
        // textarea {
        //   background: #ffffff;
        //   border: 1px solid #cccccc;
        //   font-weight: normal;
        //   font-size: 16px;
        //   line-height: 26px;
        //   letter-spacing: 0.02em;
        //   padding: 1rem;
        //   color: #333;
        //   width: 100%;

        //   &::placeholder {
        //     color: #898581;
        //   }
        // }

        .form-item {
          flex-basis: 100%;
          margin-bottom: 2rem;

          &.webform-component--first-name,
          &.webform-component--last-name,
          &.webform-component--email-address,
          &.webform-component--phone-number {
            flex-basis: 48%;
          }
        }

      }
    }

    .contact-info {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      width: 40%;

      @include less-than($screen-lg) {
        width: 100%;
      }

      a {
        color: $color-primary;

        label {
          color: #252525;
        }
      }

      h2 {
        font-weight: bold;
        font-size: 40px;
        line-height: 54px;
        text-transform: uppercase;
        color: #323232;

        &:before {
          content: "\f879";
          font-family: $font-awesome;
          color: #ffcd9e;
          margin-right: 1rem;
        }

        a{
          color: inherit;
        }

      }

      .email-info{
        label{
          padding-right: 1rem;
        }
      }

    }
  }
}
