@mixin form {
  color: #666;

  input.form-text,
  select,
  textarea {
    border: 1px solid #ddd;
    width: 100%;
    padding: 1rem;
  }

  button[type='submit'] {
    min-width: 12rem;
    height: 4rem;
    @include button;
  }

  @include less-than($screen-sm) {
    width: 100%;
  }

  .form-item {
    width: 100%;
    margin-bottom: 2rem;

    > label {
      color: #000;
      font-weight: bold;
      margin-bottom: 0.5rem;
      display: block;

      .label {
        display: none;
      }
    }

    .help-block {
      display: none !important;
    }

    &.radio {
      margin-bottom: unset;
      label {
        font-weight: normal;
        color: #666;

        input[type='radio'] {
          display: inline-block;
          margin-right: 1rem;
        }
      }
    }
  }
}
