/*------------------------------------*\
  Pagination
\*------------------------------------*/
.pagination{
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    @apply 
    tw-w-full
    tw-flex
    tw-justify-center
    tw-mt-16
    tw-pt-8
    tw-pb-8;
    li{
        @apply tw-px-3;
        a{
          color: $color-primary;
        }
        &.active{
          @apply tw-font-bold;
        }
    }

    @include larger-than($screen-md){
      li{
        @apply tw-px-4;
      }
    }
}